import React from 'react'
import Navigation from './Navigation'
import Footer from './Footer'


class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div style={{backgroundColor: "#000", color: "#fff"}}>
        <Navigation />
        <div role="main">
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Layout
