import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { Navbar , Nav, NavItem} from 'react-bootstrap'

const NavContainer = styled.div`
  background-color: #000;
  a.nav-link {
    font-size: 1rem;
    margin-right: 10px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    box-shadow: none;
  }
  a.home-link {
    font-weight: 700;
    font-size: 1.25rem;
    text-decoration: none;
    box-shadow: none;
  }
`

class Navigation extends React.Component {
  constructor(props) {
    super(props);
      this.toggleNavbar = this.toggleNavbar.bind(this);
      this.state = {
        collapsed: true,
      };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }
  render() {
    const classDropdownMenu = 'navbar-collapse' + (this.state.collapsed ? ' collapse' : '')

    return (
      <NavContainer>
        <nav className="navbar navbar-custom navbar-expand-md navbar-dark">
          <div className="container" role="navigation">
            <Link className="home-link" to="/">
              {' '}
              JENNIFER WADELLA{' '}
            </Link>
            <button className="navbar-toggler"
              type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation"
              onClick={this.toggleNavbar}>
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={classDropdownMenu} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">
                    {' '}
                    BLOG{' '}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/speaking">
                    {' '}
                    SPEAKING{' '}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/press">
                    {' '}
                    PRESS{' '}
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </NavContainer>
    )
  }
}

export default Navigation
