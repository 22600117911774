import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <div className="footer" role="contentinfo">
        <div className="container text-center">
          <p>Copyright © Jennifer Wadella 2023</p>
        </div>
      </div>
    )
  }
}

export default Footer
